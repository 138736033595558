import React from 'react'
import { Box, Heading, Button, Card, Image, Text, Flex, Link } from 'rebass'
import face from './media/images/face.jpg';
import linkedIn from './media/images/linkedin-icon.png';
import pdf from './media/images/pdf-icon.png';
import resume from './media/pdf/DalSanto_Resume.pdf';
import email from './media/images/email-icon.png';
import './App.css';

function App() {
  return (
    <div>
    <Flex alignItems='center' justifyContent='center' sx={{pt: 6}}>
        <Flex alignSelf='flex-start'></Flex>
        <Flex maxWidth='70%'>
            <Card
                sx={{
                    p: 1,
                    borderRadius: 2,
                    boxShadow: '0 0 16px rgba(0, 0, 0, .25)',
                }}>
                <Image src={face} />
                <Box px={2}>
                    <Heading as='h3'>
                        Matthew Dal Santo
                    </Heading>

                    <Box sx={{pb: 1}}></Box>

                    <Flex alignItems='flex-start' flexDirection='column'>
                        <Flex justifyContent='left'>
                            <Box px={2}><Image sx={{width: '1.5em'}} src={linkedIn} /></Box>
                            <Link href="https://www.linkedin.com/in/matthew-j-dalsanto/" alignItems='center' fontSize={0}>
                                LinkedIn
                            </Link>
                        </Flex>

                        <Box sx={{pb: 1}}></Box>

                        <Flex>
                            <Box px={2}><Image sx={{width: '1.5em'}} src={pdf} /></Box>
                            <Link href={resume} textAlign='center' fontSize={0}>
                                Resume
                            </Link>
                        </Flex>

                    </Flex>
                    <Box sx={{pb: 1}}></Box>
                </Box>
            </Card>
        </Flex>
        <Flex alignSelf='flex-end'></Flex>
    </Flex>
        <Flex sx={{position: 'fixed', bottom: 0, left: 0}}>
            <Box px={2}><Image sx={{width: '1.5em'}} src={email} /></Box>
            <Link href='mailto:matthew.j.dalsanto@sudosanto.com' textAlign='center' fontSize={0}>
                Email(matthew.j.dalsanto@sudosanto.com)
            </Link>
        </Flex>
    </div>

  );
}

export default App;
